// // import { Add } from '@mui/icons-material'
// import { Box, Fab, Modal, Tooltip } from '@mui/material'
// import React, { useState } from 'react'
// import {Add as AddIcon} from "@mui/icons-material"

// const App = () => {
//   const [open, setOpen] = useState(false)
//   return (
//     <Tooltip onClick={e=>setOpen(true)} 
//     title="Delete" sx={{position:"fixed", bottom:20 , left:{xs:"calc(50% -25px)" , md:30}}} >
//         <Fab color="primary" aria-label="add">
//           <AddIcon />
//         </Fab>
//     </Tooltip>

// <Modal
// open={open}
// onClose={e=>setOpen(false)}
// aria-labelledby="modal-modal-title"
// aria-describedby="modal-modal-description"
// >
//   <Box>Hello</Box>
// </Modal>
//   )
// }

// export default App



// import { Add } from '@mui/icons-material'
import { Avatar, Box, Button, ButtonGroup, Fab, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Add as AddIcon, DateRange, EmojiEmotions, Image, PersonAdd, VideoCameraBack } from '@mui/icons-material'
import styled from '@emotion/styled'

const StyledModal = styled(Modal) ({
  display: "flex",
  alignItems: "center",
  justifyContent:"center"
})

const UserBox = styled(Box) ({
  display: "flex",
  alignItems: "center",
  gap:"10px",
  marginBottom:"20px"
})


const App = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Tooltip onClick={e => setOpen(true)} 
        title="Delete" sx={{ position: "fixed", bottom: 20, left: { xs: "calc(50% - 25px)", md: 30 } }}>
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </Tooltip>

      <StyledModal
        open={open}
        onClose={e => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box width={500} height={300} bgcolor={"background.default"} color={"text.primary"} p={3} borderRadius={5}>
          <Typography variant='h6' color="gray" textAlign="center">
            Create Post
          </Typography>
          <UserBox>
          <Avatar 
                sx={{width: 30,height: 30}} 
                src='./avatar.jpg'
            />
             <Typography fontWeight={500} varient="span" >Huzaifa</Typography>
          </UserBox>
          <TextField
          sx={{width:"100%"}}
          id="standard-multiline-static"
          multiline
          rows={3}
          placeholder="What's on your mind?"
          variant="standard"
        />
        <Stack direction="row" gap={1} mt={2} mb={3}>
          <EmojiEmotions color="primary"/>
          <Image sx={{color:"purple"}} />
          <VideoCameraBack color="success"/>
          <PersonAdd color="error"/>
        </Stack>
        <ButtonGroup
        fullWidth variant="contained" aria-label="outlined primary button group">
          <Button>Post</Button>
          <Button sx={{width:"100px"}} >
            <DateRange />
          </Button>
        </ButtonGroup>
        </Box>
      </StyledModal>
    </>
  )
}

export default App
